<template>
  <div class="vlt-placeholder" :style="gridStyle">
    <div class="vlt-div" v-for="(num, key) in numOfTimes" :key="key">
      <img
        :src="orientation === 'LANDSCAPE' ? imageLandscape : imagePotrait"
        alt="placeholder-image"
        :class="orientation === 'LANDSCAPE' ? 'landscape':'potrait'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Placeholder",
  data() {
    return {
      imageLandscape: require("@/assets/icons/placeholder-landscape.png"),
      imagePotrait: require("@/assets/icons/placeholder-potrait.png")
    };
  },
  props: ["orientation","numOfTimes","gridStyle"]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";
.vlt-placeholder {
  display: grid;
  .vlt-div {
    .landscape,
    .potrait {
      border-radius: 10px;
      width:100%;
    }
  }
}
</style>